import { Button } from '@nextui-org/react';
import { Card } from '@nextui-org/react';
import { CardBody } from '@nextui-org/react';
import { CardHeader } from '@nextui-org/react';
import { ChevronDownSquare } from 'lucide-react';
import { KanbanSquare } from 'lucide-react';
import { MenuSquare } from 'lucide-react';
import { PanelRight } from 'lucide-react';
import { PanelTop } from 'lucide-react';
import { PanelTopOpen } from 'lucide-react';
import { XCircle } from 'lucide-react';
import { useState } from 'react';
import TokenInfo from './TokenInfo';
import LimitOrder from './LimitOrder';
import OrderBook from './OrderBook';
import TransactionList from './TransactionList';
import TokenBalanceTable from './TokenBalanceTable';

const Dashboard = () => {
  const [showSidebar, setSidebarState] = useState(true);
  const [widgets, setWidgets] = useState([]);

  const toggleSidebar = () => {
    setSidebarState(!showSidebar);
  };

  const addWidget = (element) => {
    setWidgets([...widgets, {
      title: "New Widget",
      content: element
    }]);
  };

  const removeWidget = index => {
    const newWidgets = widgets.filter((w, i) => i !== index);
    setWidgets(newWidgets);
  };

  return (
    <div className="flex-grow flex h-full w-full dark:bg-black">
      {showSidebar &&
      <div className="flex flex-col justify-start items-center text-black dark:text-white bg-white dark:bg-black p-2 w-12 border-r dark:border-gray-800">
        <Button variant="text" className="mb-2 w-full" onClick={toggleSidebar}>
          <MenuSquare className="h-4 w-4 mr-2" />
        </Button>
        <Button variant="text" className="mb-2 w-full" onClick={() => addWidget(TokenInfo)}>
          <PanelTop className="h-4 w-4 mr-2" />
        </Button>
        <Button variant="text" className="mb-2 w-full" onClick={() => addWidget(LimitOrder)}>
          <PanelTopOpen className="h-4 w-4 mr-2" />
        </Button>
        <Button variant="text" className="mb-2 w-full" onClick={() => addWidget(OrderBook)}>
          <ChevronDownSquare className="h-4 w-4 mr-2" />
        </Button>
        <Button variant="text" className="mb-2 w-full" onClick={() => addWidget(TransactionList)}>
          <KanbanSquare className="h-4 w-4 mr-2" />
        </Button>
        <Button variant="text" className="mb-2 w-full" onClick={() => addWidget(TokenBalanceTable)}>
          <PanelRight className="h-4 w-4 mr-2" />
        </Button>
      </div>
      }
      {!showSidebar &&
        <Button variant="text" className="mb-2 p-4" onClick={toggleSidebar}>
          <MenuSquare className="h-4 w-4 mr-2" />
        </Button>
      }

      <main className="flex-grow p-4 overflow-auto">
        {widgets.map((widget, index) => (
          <Card shadow="sm" isPressable style={{ marginBottom: '10px' }} key={index}>
            <CardHeader>
              <Button
                size="mini"
                auto
                isIconOnly
                onClick={() => removeWidget(index)}
                className="absolute top-1 right-1"
                color="danger"
                flat
              >
                <XCircle size={16} />
              </Button>
            </CardHeader>
            <CardBody>
              <widget.content/>
            </CardBody>
          </Card>
        ))}
      </main>
    </div>
  );
};

export default Dashboard;
