
import { ScrollShadow } from '@nextui-org/react';
import { Table } from '@nextui-org/react';
import { TableBody } from '@nextui-org/react';
import { TableCell } from '@nextui-org/react';
import { TableColumn } from '@nextui-org/react';
import { TableHeader } from '@nextui-org/react';
import { TableRow } from '@nextui-org/react';
import { ClipboardCopy } from 'lucide-react';
// Mock transaction data
const transactions = [
    { type: 'buy', from: '0x12345678...', to: '0x87654321...', amount: '1 BTC', hash: '0xabcd1234...' },
    { type: 'sell', from: '0x12345678...', to: '0x87654321...', amount: '0.5 BTC', hash: '0x1234abcd...' },
];

// Copy the transaction hash
const handleCopyHash = (hash:any) =>
    navigator.clipboard.writeText(hash);

const TransactionList = () => (
    <div className="p-8 w-full dark:bg-black">
        <ScrollShadow hideScrollBar className="w-full h-96 mb-6 overflow-auto dark:overflow-y-scroll">
            <Table aria-label="Transaction table">
                <TableHeader>
                    <TableColumn>Type</TableColumn>
                    <TableColumn>From</TableColumn>
                    <TableColumn>To</TableColumn>
                    <TableColumn>Amount</TableColumn>
                    <TableColumn>Transaction Hash</TableColumn>
                    <TableColumn>Actions</TableColumn>
                </TableHeader>
                <TableBody>
                    {transactions.map((transaction, index) => (
                        <TableRow key={index} className={`text-white ${transaction.type === 'buy' ? 'bg-green-500' : 'bg-red-500'}`}>
                            <TableCell>{transaction.type}</TableCell>
                            <TableCell>{transaction.from}</TableCell>
                            <TableCell>{transaction.to}</TableCell>
                            <TableCell>{transaction.amount}</TableCell>
                            <TableCell>{transaction.hash}</TableCell>
                            <TableCell>
                                <button onClick={() => handleCopyHash(transaction.hash)} className="py-1 px-2 rounded bg-blue-500 inline-flex items-center text-white">
                                    <ClipboardCopy className="h-4 w-4 mr-2" />
                                    Copy Hash
                                </button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </ScrollShadow>
    </div>
);

export default TransactionList;
