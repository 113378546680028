import React from 'react';
import logo from './logo.svg';
import './App.css';
import Dashboard from './components/Dashboard.js';
import Header from './components/Header';

import { Web3OnboardProvider, init } from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect';
const wcInitOptions = {
  projectId: '3f89058a5ca62a86c8b9c0812e62978f',
  requiredChains: [1],
  dappUrl: 'https://sniper-webapp.vercel.app/'
}

// initialize the module with options
const walletConnect = walletConnectModule(wcInitOptions)


const wallets = [injectedModule(), walletConnect]
const chains = [
  {
    id: 1,
    token: 'ETH',
    label: 'Ethereum Mainnet',
    //rpcUrl: 'http://127.0.0.1:8545/'
    rpcUrl: 'https://eth.llamarpc.com	'
    //rpcUrl: 'https://mainnet.infura.io/v3/${INFURA_ID}'
  },
];

const appMetadata = {
  name: 'Sniper',
  icon: 'https://pbs.twimg.com/profile_images/1551422844394819584/qO90XpkI_400x400.jpg',
  //logo: 'https://pbs.twimg.com/profile_images/1551422844394819584/qO90XpkI_400x400.jpg',
  description: 'Sniper Tools',
  recommendedInjectedWallets: [
    { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
    { name: 'MetaMask', url: 'https://metamask.io' },
    { name: 'Frame.sh', url: 'https://frame.sh' }
  ]
}

const web3Onboard = init({
  wallets,
  chains,
  appMetadata,
  theme: 'dark',
});

function App() {
  return (
    <Web3OnboardProvider web3Onboard={web3Onboard}>
      <div className="App min-h-screen flex flex-col items-center justify-start bg-white dark:bg-black">
        <Header />
        <Dashboard />
      </div>
    </Web3OnboardProvider>
  );
}

export default App;
