import { Button } from '@nextui-org/react';
import { Card } from '@nextui-org/react';
import { Image } from '@nextui-org/react';
import { Banknote } from 'lucide-react';
import { Leaf } from 'lucide-react';
import { Percent } from 'lucide-react';
import { PiggyBank } from 'lucide-react';
import { ShieldEllipsis } from 'lucide-react';
import { Volume2 } from 'lucide-react';

export default function TokenInfo() {
  const tokenData = {
    tokenImage: "https://pbs.twimg.com/profile_images/1551422844394819584/qO90XpkI_400x400.jpg",
    tokenName: "Token Name",
    price: "$1234.56",
    gain: "+23.45%",
    currentLiquidity: "$4,567,890",
    dailyVolume: "$1,234,567",
    marketCap: "$8,765,432",
    totalSupply: "99,876,543",
    inflationRate: "2.5%",
    tokenAddress: "token-address"
  }

  return (
    <Card className="flex shadow flex-col items-center max-w-lg mx-auto p-5 rounded-md space-y-4 bg-white dark:bg-black">
      <div className="flex items-center justify-center space-x-4">
        <Image className="rounded-full" src={tokenData.tokenImage} width={50} height={50} alt="Token Icon" />
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{tokenData.tokenName}</h2>
      </div>
      <div className="flex items-center justify-center space-x-4">
        <ShieldEllipsis className="h-8 w-8 text-purple-500 dark:text-purple-300" />
        <div>
          <h3 className="text-gray-900 dark:text-gray-100 text-center">Price</h3>
          <p className="text-purple-500 text-lg dark:text-purple-300 text-center">{tokenData.price}</p>
        </div>
        <div>
          <h3 className="text-gray-900 dark:text-gray-100 text-center">24h Gain</h3>
          <p className="text-green-600 text-lg dark:text-green-300 text-center">{tokenData.gain}</p>
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex items-center justify-center space-x-4">
          <Leaf className="h-6 w-6 text-green-600 dark:text-green-300" />
          <h3 className="text-gray-900 dark:text-gray-100 text-center">Current Liquidity</h3>
          <p className="text-lg text-gray-800 dark:text-gray-300 text-center">{tokenData.currentLiquidity}</p>
        </div>
        <div className="flex items-center justify-center space-x-4">
          <Volume2 className="h-6 w-6 text-blue-600 dark:text-blue-300" />
          <h3 className="text-gray-900 dark:text-gray-100 text-center">Daily Volume</h3>
          <p className="text-lg text-gray-800 dark:text-gray-300 text-center">{tokenData.dailyVolume}</p>
        </div>
        <div className="flex items-center justify-center space-x-4">
          <Banknote className="h-6 w-6 text-green-600 dark:text-green-300" />
          <h3 className="text-gray-900 dark:text-gray-100 text-center">Market Cap</h3>
          <p className="text-lg text-gray-800 dark:text-gray-300 text-center">{tokenData.marketCap}</p>
        </div>
        <div className="flex items-center justify-center space-x-4">
          <PiggyBank className="h-6 w-6 text-yellow-500 dark:text-yellow-300" />
          <h3 className="text-gray-900 dark:text-gray-100 text-center">Total Supply</h3>
          <p className="text-lg text-gray-800 dark:text-gray-300 text-center">{tokenData.totalSupply}</p>
        </div>
        <div className="flex items-center justify-center space-x-4">
          <Percent className="h-6 w-6 text-red-600 dark:text-red-300" />
          <h3 className="text-gray-900 dark:text-gray-100 text-center">Inflation Rate</h3>
          <p className="text-lg text-gray-800 dark:text-gray-300 text-center">{tokenData.inflationRate}</p>
        </div>
      </div>
      <div className="flex justify-center">
        <Button 
          size="lg" 
          variant="faded" 
          href={`https://etherscan.io/token/${tokenData.tokenAddress}`} 
          rel="noopener noreferrer" 
          target="_blank">
          <Image src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg" width={32} height={32} alt="Etherscan icon" />
          View on Etherscan
        </Button>
      </div>
    </Card>
  );
}
