import { Avatar } from '@nextui-org/react';
import { Tooltip } from '@nextui-org/react';
import { Button } from '@nextui-org/react';
import { Link } from '@nextui-org/react';
import { Navbar } from '@nextui-org/react';
import { NavbarBrand } from '@nextui-org/react';
import { NavbarContent } from '@nextui-org/react';
import { NavbarItem } from '@nextui-org/react';
import { Wallet2 } from 'lucide-react';
import { User } from 'lucide-react';
import UserWalletInfoCard from './UserWalletInfoCard';
import { ethers } from 'ethers';
import React from 'react';
import { useConnectWallet } from '@web3-onboard/react'


const Header: React.FC = () => {
  const [isConnected, setIsConnected] = React.useState(false);
  const [isUserInfoOpen, setIsUserInfoOpen] = React.useState(false);
  const [walletAddress, setWalletAddress] = React.useState<string>();
  const [ensName, setEnsName] = React.useState<string>();
  const [ensAvatarUrl, setEnsAvatarUrl] = React.useState<string>();
  const [
    {
      wallet, // the wallet that has been connected or null if not yet connected
      connecting // boolean indicating if connection is in progress
    },
    connect, // function to call to initiate user to connect wallet, returns a list of WalletState objects (connected wallets)
    disconnect, // function to call with wallet<DisconnectOptions> to disconnect wallet, returns a list of WalletState objects (connected wallets)
    updateBalances, // function to be called with an optional array of wallet addresses connected through Onboard to update balance or empty/no params to update all connected wallets
    setWalletModules, // function to be called with an array of wallet modules to conditionally allow connection of wallet types i.e. setWalletModules([ledger, trezor, injected])
    setPrimaryWallet // function that can set the primary wallet and/or primary account within that wallet. The wallet that is set needs to be passed in for the first parameter and if you would like to set the primary account, the address of that account also needs to be passed in
  ] = useConnectWallet();

  const toggleUserCard = () => {
    setIsUserInfoOpen(!isUserInfoOpen);
  }

  const connectWallet = async () => {
    const wallets = await connect();
    console.log("wallets: ", wallets);
    const wallet = wallets[0];

    if (wallet) {
      // create an ethers provider with the last connected wallet provider
      const provider = new ethers.providers.Web3Provider(
        wallet.provider,
        'any'
      )
      const signer = provider.getSigner()
      const address = await signer.getAddress();

      setIsConnected(true);
      setWalletAddress(address);

      try {
        const name = await provider.lookupAddress(address);
        console.log("name: ", name);
        if (name) {
          setEnsName(name);
          const resolver = await provider.getResolver(name);
          const avatar = await resolver?.getAvatar();
          if (avatar) {
            setEnsAvatarUrl(avatar.url);
          }
        }
      } catch (e) {
        console.log(e);
      }

      // Create a message to sign
      const message = `Hello, ${address}!`;
      // should get a nonce from the backend to sign so I can verify in the backend the user is correct

      // Sign the message
      signer.signMessage(message).then(async (signature) => {
        console.log(signature);
        // Logic for connecting to Wallet goes here
        // Should connect to a backend with signature
        setIsConnected(true);
        setWalletAddress(address);
      }).catch(e => console.log(e));
    }
  }

  return (
    <>
      <Navbar className="flex-col overflow-auto">
        <NavbarBrand className="font-bold text-2xl text-blue-500 dark:text-blue-300">
          Sniper
        </NavbarBrand>
        <NavbarContent justify="end">
          {isConnected ? (
            <>
              <Tooltip content="View User Information">
                <Button color="default" onClick={toggleUserCard} radius="sm" className="flex items-center gap-2">
                  <Avatar name={ensName ?? walletAddress} size="sm" radius="sm" className="mr-2" src={ensAvatarUrl}/>
                  <span className="font-medium text-gray-800 dark:text-gray-200">{ensName ?? walletAddress}</span>
                </Button>
              </Tooltip>
            </>
          ) : (
            <Button color="primary" onClick={connectWallet} className="flex items-center gap-2">
              <Wallet2 className="h-4 w-4" />
              <span>Connect Wallet</span>
            </Button>
          )}
        </NavbarContent>
      </Navbar>
      {isUserInfoOpen && isConnected && (
        <UserWalletInfoCard />
      )}
    </>
  );
};

export default Header;
