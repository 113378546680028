import { ScrollShadow } from '@nextui-org/react';
import { Spinner } from '@nextui-org/react';
import { Table } from '@nextui-org/react';
import { TableBody } from '@nextui-org/react';
import { TableCell } from '@nextui-org/react';
import { TableColumn } from '@nextui-org/react';
import { TableHeader } from '@nextui-org/react';
import { TableRow } from '@nextui-org/react';
import { ArrowDownZA } from 'lucide-react';
import { ArrowUp01 } from 'lucide-react';

const TokenBalanceTable = () => {
  return (
    <ScrollShadow className="w-full h-full max-h-[400px] dark:bg-black">
      <Table aria-label="Token Balances" className="w-full text-left">
        <TableHeader>
          <TableColumn className="cursor-pointer">
            Token Name
            <ArrowUp01 className="inline ml-2 h-4 w-4" />
          </TableColumn>
          <TableColumn className="cursor-pointer">
            Token Symbol
            <ArrowUp01 className="inline ml-2 h-4 w-4" />
          </TableColumn>
          <TableColumn className="cursor-pointer">
            Balance
            <ArrowDownZA className="inline ml-2 h-4 w-4" />
          </TableColumn>
          <TableColumn className="cursor-pointer">
            Value
            <ArrowDownZA className="inline ml-2 h-4 w-4" />
          </TableColumn>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>Bitcoin</TableCell>
            <TableCell>BTC</TableCell>
            <TableCell>
              <Spinner />
            </TableCell>
            <TableCell>
              <Spinner />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Ethereum</TableCell>
            <TableCell>ETH</TableCell>
            <TableCell>45.12</TableCell>
            <TableCell>61,352.23 USD</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cardano</TableCell>
            <TableCell>ADA</TableCell>
            <TableCell>
              <Spinner />
            </TableCell>
            <TableCell>
              <Spinner />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </ScrollShadow>
  );
};

export default TokenBalanceTable;
