import { Spinner } from '@nextui-org/react';
import { Switch } from '@nextui-org/react';
import { Table } from '@nextui-org/react';
import { TableBody } from '@nextui-org/react';
import { TableCell } from '@nextui-org/react';
import { TableColumn } from '@nextui-org/react';
import { TableHeader } from '@nextui-org/react';
import { TableRow } from '@nextui-org/react';
import { Tooltip } from '@nextui-org/react';
import { ArrowDownCircle } from 'lucide-react';
import { ArrowUp01 } from 'lucide-react';
import { useEffect } from 'react';
import { useState } from 'react';

export default function OrderBook() {
  const [switchState, setSwitchState] = useState(false);
  const [loading, setLoading] = useState(true);
  const SampleData = [
    { "Order ID": "001", "Price": "100", "Quantity": "500", "Time": "12:00 PM", "Seller/Buyer": "John Doe" },
    { "Order ID": "002", "Price": "150", "Quantity": "200", "Time": "1:00 PM", "Seller/Buyer": "Jane Doe" },
    { "Order ID": "003", "Price": "120", "Quantity": "250", "Time": "2:00 PM", "Seller/Buyer": "Mike Doe" },
  ]

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex flex-col dark:bg-black">
      {loading ? <Spinner size="md" color="success"/> : null}
      <Switch
        isSelected={switchState}
        onValueChange={setSwitchState}
        color="success"
      >
        <span className="right-0 mx-2">Switch Sell / Buy Order View</span>
      </Switch>
      {!loading && 
      <Table aria-label="Order Book">
        <TableHeader>
          <TableColumn>Order ID</TableColumn>
          <TableColumn>Price <ArrowUp01 className="h-4 w-4 inline-flex" /></TableColumn>
          <TableColumn>Quantity <ArrowDownCircle className="h-4 w-4 inline-flex" /></TableColumn>
          <TableColumn>Time</TableColumn>
          <TableColumn>Seller/Buyer</TableColumn>
        </TableHeader>
        <TableBody>
          {SampleData.map((value, index) => (
            <TableRow key={index}>
              {/*<Tooltip content={`Order ID: ${value["Order ID"]}\nPrice: ${value["Price"]}\nQuantity: ${value["Quantity"]}\nBuyer/Seller: ${value["Seller/Buyer"]}`}>*/}
              <TableCell>{value["Order ID"]}</TableCell>
              <TableCell>{value["Price"]}</TableCell>
              <TableCell>{value["Quantity"]}</TableCell>
              <TableCell>{value["Time"]}</TableCell>
              <TableCell>{value["Seller/Buyer"]}</TableCell>
              {/*</Tooltip>*/}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      }
    </div>
  );
}
