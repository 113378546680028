import { Card } from '@nextui-org/react';
import { Spacer } from '@nextui-org/react';
import { Table } from '@nextui-org/react';
import { TableBody } from '@nextui-org/react';
import { TableCell } from '@nextui-org/react';
import { TableColumn } from '@nextui-org/react';
import { TableHeader } from '@nextui-org/react';
import { TableRow } from '@nextui-org/react';
import { User } from '@nextui-org/react';
import { Coins } from 'lucide-react';
import { Hexagon } from 'lucide-react';
import { PiggyBank } from 'lucide-react';
import { Wallet2 } from 'lucide-react';
import { WalletCards } from 'lucide-react';
import React from 'react';

const UserWalletInfoCard: React.FC = (props:any) => {
  return (
    <Card className="absolute top-20 right-20 p-4 space-y-4 ">
      <div className="flex flex-col items-center mb-2">
        <User
          name={props.address}
          avatarProps={{
            src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
          }}
        />
        <Spacer y={1} />
        <div className="flex justify-center gap-4 mt-4">
          <Wallet2 className="h-6 w-6 text-gradient" />
          <WalletCards className="h-6 w-6 text-gradient" />
          <PiggyBank className="h-6 w-6 text-gradient" />
          <Coins className="h-6 w-6 text-gradient" />
        </div>
      </div>

      <div className="flex flex-col items-center gap-2 mt-2">
        <div className="flex items-center justify-center">
          <Hexagon className="h-6 w-6 text-green-500 dark:text-green-400" />
          <span className="ml-2 text-sm">6400 USDT</span>
        </div>
        <Spacer y={0.5} />
        <div className="flex items-center justify-center">
          <Wallet2 className="h-6 w-6 text-green-500 dark:text-green-400" />
          <span className="ml-2 text-sm">12 ETH</span>
        </div>
      </div>

      <Table>
        <TableHeader>
          <TableColumn>Token</TableColumn>
          <TableColumn>Balance</TableColumn>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>DAI</TableCell>
            <TableCell>1200</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ETH</TableCell>
            <TableCell>4</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>USDT</TableCell>
            <TableCell>4000</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default UserWalletInfoCard;
