import { Button } from '@nextui-org/react';
import { Card } from '@nextui-org/react';
import { CardBody } from '@nextui-org/react';
import { Input } from '@nextui-org/react';
import { Modal } from '@nextui-org/react';
import { ModalContent } from '@nextui-org/react';
import { ModalFooter } from '@nextui-org/react';
import { Popover } from '@nextui-org/react';
import { PopoverContent } from '@nextui-org/react';
import { PopoverTrigger } from '@nextui-org/react';
import { Spacer } from '@nextui-org/react';
import { Switch } from '@nextui-org/react';
import { useDisclosure } from '@nextui-org/react';
import { HelpCircle } from 'lucide-react';
import { Settings } from 'lucide-react';
import { useEffect } from 'react';
import { useState } from 'react';
import React from 'react';

const LimitOrder: React.FC = () => {
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [cost, setCost] = useState('');
  const [slippage, setSlippage] = useState('');
  const [autoSlippage, setAutoSlippage] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  
  useEffect(() => {
    const cost = parseFloat(price) * parseFloat(quantity);
    setCost(isNaN(cost) ? '' : cost.toFixed(2));
  }, [price, quantity]);

  const handleSubmit = (type: string) => {
    alert(`Order submitted. Action: ${type.toUpperCase()}, Price: ${price}, Quantity: ${quantity}`);
  };

  const popoverContent = (
    <PopoverContent>
      <div>
        A limit order is a type of order to purchase or sell an asset at a specified price or better.
      </div>
    </PopoverContent>
  );

  return (
    <Card shadow="lg" className="my-4 max-w-xs mx-auto rounded-lg shadow-md overflow-hidden md:max-w-2xl">
      <CardBody className="p-4 relative">
        <div className="flex items-center justify-center">
          <h2 className="text-xl font-bold text-gray-700 dark:text-white">Limit Order</h2>
          <Spacer x={1} />
          <Popover>
            <PopoverTrigger>
              <button className="focus:outline-none">
                <HelpCircle className="h-4 w-4 text-gray-500 dark:text-gray-300"/>
              </button>
            </PopoverTrigger>
            {popoverContent}
          </Popover>
        </div>
        <button className="absolute right-4 top-4 focus:outline-none" onClick={onOpen}>
          <Settings className="h-5 w-5 text-gray-500 dark:text-gray-300"/>
        </button>
        <Spacer y={2} />
        <div className="space-y-4">
          <Input
            className="w-full mx-auto"
            color="primary"
            placeholder="Price in USD"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            label="Price"
          />
          <Input
            className="w-full mx-auto"
            color="primary"
            placeholder="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            label="Quantity"
          />
          {cost !== '' && <p className="text-gray-500 text-right">Cost: {cost}</p>}
        </div>
        <Spacer y={1} />
        <div className="flex mt-2 gap-2">
          <Button
            className="w-1/2 flex-grow"
            color="success"
            onClick={() => handleSubmit('buy')}
          >
            Buy
          </Button>
          <Button
            className="w-1/2 flex-grow"
            color="danger"
            onClick={() => handleSubmit('sell')}
          >
            Sell
          </Button>
        </div>
        <Modal isOpen={isOpen} onClose={onClose} size="lg">
          <ModalContent>
            <div className="p-4">
              <h3 className="text-lg font-medium text-gray-800 dark:text-white">Settings</h3>
              <Spacer y={1} />
              <div className="r">
                <Input
                  className="w-full"
                  color="primary"
                  placeholder="Slippage Amount"
                  value={slippage}
                  onChange={(e) => setSlippage(e.target.value)}
                  label="Slippage"
                />
                <Spacer y={1} />
                <Switch isSelected={autoSlippage} onValueChange={setAutoSlippage}>
                  Auto Slippage
                </Switch>  
              </div>
              <Spacer y={1} />
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" onPress={onClose}>
                  Save
                </Button>
              </ModalFooter>
            </div>
          </ModalContent>
        </Modal>
      </CardBody>
    </Card>
  );
};

export default LimitOrder;
